import { FlowDropdownInput } from "#batteries-included-components/Dropdowns/FlowDropdownInput";
import { AssetGroupDropdownInput } from "#src/batteries-included-components/Dropdowns/AssetGroupDropdownInput";
import { DeviceDropdownInput } from "#src/batteries-included-components/Dropdowns/DeviceDropdownInput";
import { EquipmentDropdownInput } from "#src/batteries-included-components/Dropdowns/EquipmentDropdownInput";
import { FacilityDropdownInput } from "#src/batteries-included-components/Dropdowns/FacilityDropdownInput";
import { FormSchemaDropdownInput } from "#src/batteries-included-components/Dropdowns/FormSchemaDropdownInput";
import {
  DateSelectorInput,
  DropdownInput,
  GeoPointInput,
  RadioInput,
  TextInput,
} from "@validereinc/common-components";
import { AssetType, CustomAttributeType, Resources } from "@validereinc/domain";
import startCase from "lodash/startCase";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";

const BACKEND_UNSUPPORTED_FILTER_TYPES = ["number-array", "geo_point"];

const CustomAttributeField = ({
  attribute,
  isDisabled,
  subtype,
  isOptionalTextShown,
  isFilterForm,
  name,
}: {
  attribute: CustomAttributeType;
  subtype?: string;
  isDisabled?: boolean;
  isOptionalTextShown?: boolean;
  isFilterForm?: boolean;
  name?: string;
}) => {
  const {
    data_type,
    is_required,
    field_name,
    display_name,
    entity_subtype,
    unit,
    description,
    pick_list_values,
    lookup_entity_type,
  } = attribute;

  const commonProps = {
    name: name || `custom_attributes.${field_name}`,
    label: display_name,
    isRequired: isFilterForm ? false : is_required,
    isDisabled,
    unit,
    description,
    isOptionalTextShown: isFilterForm ? false : isOptionalTextShown,
  };

  const pickListValues = useMemo(() => {
    if (pick_list_values?.length && typeof pick_list_values?.[0] === "string") {
      return pick_list_values.map((value) => ({
        id: value,
        name: startCase(value),
      }));
    }

    return pick_list_values;
  }, [pick_list_values]);

  if (isFilterForm && BACKEND_UNSUPPORTED_FILTER_TYPES.includes(data_type)) {
    return null;
  }

  if (
    (!subtype && entity_subtype) ||
    (subtype && entity_subtype && entity_subtype !== subtype)
  ) {
    return null;
  }
  switch (data_type) {
    case "number-array":
    case "string":
      return <TextInput {...commonProps} />;
    case "number":
      return (
        <TextInput
          {...commonProps}
          type="number"
        />
      );
    case "integer":
      return (
        <TextInput
          {...commonProps}
          type="number"
          step={1}
        />
      );
    case "date":
      return (
        <DateSelectorInput
          {...commonProps}
          isFluid
        />
      );
    case "lookup":
      switch (lookup_entity_type) {
        case AssetType.FACILITY:
          return (
            <FacilityDropdownInput
              {...commonProps}
              isOptionalTextShown={!isFilterForm}
              isMulti={!!isFilterForm}
            />
          );
        case AssetType.EQUIPMENT:
          return (
            <EquipmentDropdownInput
              {...commonProps}
              isOptionalTextShown={!isFilterForm}
              isMulti={!!isFilterForm}
            />
          );
        case AssetType.FLOW:
          return (
            <FlowDropdownInput
              {...commonProps}
              isOptionalTextShown={!isFilterForm}
              isMulti={!!isFilterForm}
            />
          );
        case AssetType.DEVICE:
          return (
            <DeviceDropdownInput
              {...commonProps}
              isOptionalTextShown={!isFilterForm}
              isMulti={!!isFilterForm}
            />
          );
        case AssetType.ASSET_GROUP:
          return (
            <AssetGroupDropdownInput
              {...commonProps}
              isOptionalTextShown={!isFilterForm}
              isMulti={!!isFilterForm}
            />
          );
        case Resources.FORM_SCHEMA:
          return (
            <FormSchemaDropdownInput
              {...commonProps}
              isOptionalTextShown={!isFilterForm}
              isMulti={!!isFilterForm}
            />
          );
        default:
          return null;
      }
    case "date-time":
      return (
        <DateSelectorInput
          {...commonProps}
          variant="time"
          isFluid
        />
      );
    case "pick-list":
    case "multi-pick-list":
      return (
        <DropdownInput
          {...commonProps}
          options={pickListValues}
          labelKey="name"
          valueKey="id"
          isMulti={isFilterForm || data_type === "multi-pick-list"}
          isFluid
        />
      );
    case "geo_point":
      return (
        <GeoPointInput
          {...commonProps}
          isFluid
          shouldWrap
        />
      );
    case "boolean":
      return (
        <RadioInput
          {...commonProps}
          labelKey="label"
          valueKey="value"
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
      );
    default:
      return null;
  }
};

CustomAttributeField.propTypes = {
  attribute: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  subtype: PropTypes.string,
  isOptionalTextShown: PropTypes.bool,
  isFilterForm: PropTypes.bool,
};

export default CustomAttributeField;
